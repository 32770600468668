import { RootState } from '@app/store';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

interface CountdownTimerProps {
  deadline: string;
  onTimerExpired: () => void;
}

export const CountdownTimer = ({ deadline, onTimerExpired }: CountdownTimerProps) => {
  const serverTime = useSelector((state: RootState) => state.account.hedging.serverTime);

  const [timeLeft, setTimeLeft] = useState<number>(0); // В миллисекундах

  useEffect(() => {
    if (serverTime) {
      const serverDate = new Date(serverTime);
      const deadlineDate = new Date(deadline);

      const initialDifference = deadlineDate.getTime() - serverDate.getTime();

      if (initialDifference <= 0) {
        setTimeLeft(0);
        onTimerExpired();
        return;
      }

      setTimeLeft(initialDifference);

      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTimeLeft = prevTime - 1000;
          if (newTimeLeft <= 0) {
            clearInterval(intervalId);
            onTimerExpired();
            return 0;
          }
          return newTimeLeft;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [serverTime, deadline, onTimerExpired]);

  const formatTime = (milliseconds: number) => {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    return `${minutes}m ${seconds}s`;
  };

  return <span>{formatTime(timeLeft)}</span>;
};
